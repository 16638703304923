<template>
  <div>
    <div class="card no-border">
      <h2>{{video.title}}</h2>
       <div class="card-body">
         <div v-html="embed" class="mws-video-player"></div>
        <a v-bind:href="video.download_link" class="button w-inline-block post-download" target="_blank" download>
          <div v-if="!Editable">
            Download Video
            <i class="fas fa-download"></i>
          </div>
           <div v-if="Editable">
            Edit Video
               <i class="fas fa-edit"></i>
          </div>
        </a>
      </div> 
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      embed:""
    }
  },
  props: {
    video: Object,
    Editable: Boolean
  },
  created ()
  {
    //Make videos square
    var formatedEmbed = this.video.video_embed_code;
    var str = formatedEmbed.trim();
    var regexW = /width="\d\d\d"/gm;
    var regexH = /height="\d\d\d"/gm;
    str = str.replace(regexW, 'width="400"');
    str = str.replace(regexH, 'height="425"');
    formatedEmbed = str;
    this.embed = formatedEmbed;
  }
};
</script>
<style scoped>
.card{
  text-align:  center;
}
.post-download{
  margin-top: -15px;
}
.mws-video-player
{
  margin-bottom: -50px;
}
</style>
